import * as React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import QuickSearch from "../components/QuickSearch"
import MattressTopPicks from "../components/MattressTopPicks"
import MattressesByType from "../components/MattressesByType"
//import MattressesByBrand from "../components/MattressesByBrand"
import Seo from "../components/Seo/Seo"
import { graphql, useStaticQuery } from 'gatsby'


// markup
const Home = () => {
  //the query
  const data = useStaticQuery(graphql`
    query {
      datoCmsLocation(websiteUrl: {eq: "us.mattress.zone"}) {
        homepageSeoMeta {
          title
          description
          image {
            url(imgixParams: {fit: "crop", w: "1200", h: "675"})
          }
        }
        homepageTitle
        homepageHeroHeadline
        homepageHeroContentNode {
          childMarkdownRemark {
            html
          }
        }
        homepageHeroImage {
          gatsbyImageData(
            imgixParams: {fit: "crop", h: "400", w: "1600", colors: 10, auto: "format", bri: 30, con: -70}
            placeholder: BLURRED
          )
        }
      }
    }
  `)

  const meta_title = data.datoCmsLocation.homepageSeoMeta.title
  const meta_description = data.datoCmsLocation.homepageSeoMeta.description
  const meta_image = data.datoCmsLocation.homepageSeoMeta.image.url

  return (
    <Layout>
      <Seo
        title={meta_title}
        description={meta_description}
        image={meta_image}
      />

      <main>
        <Hero
          gatsbyImage={data.datoCmsLocation.homepageHeroImage}
          title={data.datoCmsLocation.homepageTitle}
          headline={data.datoCmsLocation.homepageHeroHeadline}
          content={data.datoCmsLocation.homepageHeroContentNode} />

        <QuickSearch className="bg-zone-3 py-12 px-4 xl:px-0" container={true} />

        <MattressesByType className="bg-zone-13 py-12 px-4 xl:px-0" container={true} />

        <MattressTopPicks className="bg-zone-3 py-12 px-4 xl:px-0" container={true} />
  
        {/* <MattressesByBrand className="bg-zone-13 py-12 px-4 xl:px-0" container={true} /> */}
  
      </main>
    </Layout>
  )
}

export default Home

import React from "react"
import { getVariables } from "../lib/utils"
import { useStaticQuery, graphql, Link } from "gatsby"

import srcMattress1 from "../images/mattress-1.svg"

const MattressesByType = ({ container = false, className = null }) => {
    const items = useStaticQuery(graphql`
    query {
        allDatoCmsMattress(
            filter: {location: {websiteUrl: {eq: "us.mattress.zone"}, locale: {eq: "en"}}}
        ) {
            distinct(field: mattressTypeFilter___slug)
        }
    }
    `)

    const variables = getVariables()

    const image = <img src={srcMattress1} width="60" alt="Icon" className="mr-4 zone-filter" />

    return (
        <div className={className}>
            <div className={` ${container ? 'container mx-auto' : null} `}>
                <h2 className="text-4xl text-zone-1 font-bold font-playfair mb-8">Compare Mattresses by Type</h2>

                <div className="grid gap-4 grid-cols-2 sm:grid-cols-3 xl:grid-cols-3">
                    {items.allDatoCmsMattress.distinct.map((item, index) => {
                        return (
                            <Link 
                                className="bg-zone-1 p-4 rounded-lg flex items-center text-zone-2 capitalize transition duration-500 hover:shadow-lg" 
                                to={`${variables["mattress-type"]}/${item}`} 
                                key={`key-mattresse-by-type-${index}`}
                            >
                                {image}
                                {item.replace(/-/g, ' ')}
                            </Link>
                        )
                    }
                    )}
                </div>
            </div>
        </div>
    )
}

export default MattressesByType

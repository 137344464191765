import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ListMattress from "../components/ListMattress"

const MattressTopPicks = ({ container = false, className = null }) => {

    const mattresses = useStaticQuery(graphql`
    query {
        allDatoCmsMattress(
            filter: {
                mattressTags: {
                    elemMatch: {
                        tag: {
                            eq: "Top pick"
                        }, 
                    }
                }, 
                location: {
                    websiteUrl: {
                        eq: "us.mattress.zone"
                    }
                },
                locale: {
                    eq: "en"
                }
            },
            sort: {
                fields: name, 
                order: ASC
                }
            ) {
            nodes {
                name
                slug
                locale
                location {
                    websiteUrl
                }
                mattressImage {
                    gatsbyImageData(
                        imgixParams: {auto: "format", fit: "crop", h: "125", w: "250", colors: 10, cornerRadius: "10,10,10,10", mask: "corners"}
                        placeholder: BLURRED
                    )
                }
                id
                brand {
                  slug
                }
                mattressType {
                  slug
                }
            }
        }
    }
    `)

    const today = new Date()
    
    return (
        <div className={className}>
            <div className={` ${container ? 'container mx-auto' : null} `}>
                <h2 className="text-4xl text-zone-1 font-bold font-playfair mb-4">Most Popular Mattresses {today.getFullYear()}</h2>
                <p className="text-lg text-zone-6 font-signika mb-4">Select from these best- selling mattresses</p>
            </div>
            <ListMattress container={true} mattresses={mattresses.allDatoCmsMattress.nodes} />
        </div>
    )
}

export default MattressTopPicks
import React, { useState } from "react"
import { getVariables } from "../lib/utils"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, navigate } from "gatsby"

const QuickSearch = ({ container = false, className , title = "Quick Compare", description = "Easily compare any 2 mattresses. Choose your mattress from the list below:" }) => {

    const mattresses = useStaticQuery(graphql`
    query {
        allDatoCmsMattress(
            filter: {
                locale: {eq: "en"}, 
                location: {
                    websiteUrl: {eq: "us.mattress.zone"}
                }
            }, 
            sort: {
                fields: name, 
                order: ASC
                }
            ) {
            nodes {
                name
                slug
                mattressImage {
                    gatsbyImageData(
                        imgixParams: {auto: "format", fit: "crop", h: "125", w: "250", colors: 10, cornerRadius: "10,10,10,10", mask: "corners"}
                        placeholder: BLURRED
                    )
                }
            }
        }
    }
    `)

    let [imageA, setImageA] = useState(null);
    let [imageB, setImageB] = useState(null);

    const updatePreview = ({ target }) => {
        //get the image from the name
        const imageFiltered = mattresses.allDatoCmsMattress.nodes.filter(item => item.name === target.value)
        
        //todo setState
        if (target.classList.contains('inputA')) {
            if (imageFiltered.length === 1) {
                setImageA((prev) => {
                    return getImage(imageFiltered[0].mattressImage)
                })
            } else {
                setImageA((prev) => {
                    return null
                })
            }
        } else {
            //inputB
            if (imageFiltered.length === 1) {
                setImageB((prev) => {
                    return getImage(imageFiltered[0].mattressImage)
                })
            } else {
                setImageB((prev) => {
                    return null
                })
            }
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        // Custom logic for the form submission
        const slugs = []
        const toNavigate = []
        const inputs = document.querySelectorAll('.wrap-quick-search input')

        inputs.forEach((input) => {
            //let's use the value to find the correct slug
            const itemSlug = mattresses.allDatoCmsMattress.nodes.filter(mattress => mattress.name === input.value)
            if (itemSlug.length === 1) {
                toNavigate.push(itemSlug[0].slug + '-' + variables.mattress)
                slugs.push(itemSlug[0].slug)
            }
        })

        if (slugs[0] !== slugs[1]) {
            navigate(
                '/' + variables.comparer + '/' + toNavigate.join('-vs-'),
                {
                    state: { slugs },
                }
            )
        } else {
            //the same product selected twice, redirect to single
            navigate(
                '/' + variables.mattress + '/' + toNavigate[0],
                {
                    state: { slugs },
                }
            )
        }
    }

    const variables = getVariables()

    return (
        <div className={className}>
            <div className={` ${container ? 'container mx-auto' : null} `}>
                {title && <h2 className="text-4xl text-zone-1 font-bold font-playfair mb-4">{title}</h2>}
                {description && <p className="text-lg text-zone-6 font-signika mb-4">{description}</p>}

                <form className="wrap-quick-search flex mb-8 items-center flex-col lg:flex-row lg:items-start lg:justify-center lg:mt-8" onSubmit={handleSubmit}>
                    <div className="mb-6 lg:mr-6">
                        <input 
                            list="mattressOptions" 
                            className="inputA text-2xl py-2 px-4 text-zone-3 border border-zone-2 bg-zone-2 w-full" 
                            required 
                            placeholder="Ex: Avocado Green" 
                            onChange={updatePreview} 
                            aria-label="Input mattress name"
                        />
                        <datalist id="mattressOptions">
                            {mattresses.allDatoCmsMattress.nodes.map((item, index) => {
                                return (<option aria-label="Mattress list" value={item.name} key={`key-option-${index}`} />)
                            }
                            )}
                        </datalist>

                        <div id="preview-a" className="mt-6 text-center text-zone-1">
                            {
                                imageA !== null ? <GatsbyImage
                                    image={imageA}
                                    className="rounded-2xl zone-shadow"
                                    alt="Image A" /> : 'No Preview'
                            }

                        </div>
                    </div>

                    <p className="text-zone-6 text-4xl mb-6 lg:mr-4 lg:self-center lg:mb-0">VS</p>

                    <div className="mb-6 lg:mr-6">
                        <input 
                            list="mattressOptions" 
                            className="inputB text-2xl py-2 px-4 text-zone-3 border border-zone-2 bg-zone-2 w-full" 
                            required 
                            placeholder="Ex: Happsy Organic" 
                            onChange={updatePreview} 
                            aria-label="Input mattress name"
                        />

                        <div id="preview-b" className="mt-6 text-center text-zone-1">
                            {
                                imageB !== null ? <GatsbyImage
                                    image={imageB}
                                    className="rounded-2xl zone-shadow"
                                    alt="Image B" /> : 'No Preview'
                            }
                        </div>
                    </div>

                    <button 
                        type="submit"
                        className="w-64 rounded px-2 py-2 cursor-pointer bg-zone-11 text-white transition duration-300 text-xl border-0 uppercase font-bold flex items-center justify-center hover:bg-zone-9"
                        id="btn-quick-compare"
                    >
                        <StaticImage
                            src={"../images/icon-exchange.svg"}
                            placeholder="blurred"
                            layout="fixed"
                            width={30}
                            height={30}
                            alt="Check"
                            className="mr-4"
                        />
                        <span>{variables.compare}</span>
                    </button>
                </form>
            </div>
        </div>
    )
}

export default QuickSearch
